<template>
  <div class="sipn-cont">
    <div class="sk-wave">
      <div class="sk-rect sk-rect1"></div>
      <div class="sk-rect sk-rect2"></div>
      <div class="sk-rect sk-rect3"></div>
      <div class="sk-rect sk-rect4"></div>
      <div class="sk-rect sk-rect5"></div>

      <br>

      <div v-if="isCustomPage" class="square">
        <a href="javascript:" class="square-link" @click="customPageMove(goPathStr)">
          <h4 class="title"  style="font-size: 1.5em; margin-top:2em; margin-bottom: 0.2em;">[다시연결하기]</h4>
          자동으로 화면전환이 않된 경우 <br/>[다시연결하기]버튼을 눌러주세요!!
        </a>
      </div>


      <div style="width: 300px;margin-left: -50px;margin-top: 5em;">
              <span style="color:#fff; font-size:1.5em; font-weight:700" id="waitStr">잠시만
                <br>기다려 주세요!!<br>
                <br>
                해당페이지로 이동되거나 파일이 다운로드 됩니다.
              </span>
      </div>



    </div>
  </div>
</template>
<style>
.sipn-cont {
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 999;
  background-color: #fdb913;
}

.sk-wave {
  margin: 40px auto;
  width: 200px;
  height: 30px;
  text-align: center;
  font-size: 10px;
}

.sk-wave .sk-rect {
  background-color: #fff;
  height: 100%;
  width: 6px;
  margin: 1px;
  display: inline-block;
  -webkit-animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
  animation: sk-waveStretchDelay 1.2s infinite ease-in-out;
}

.sk-wave .sk-rect1 {
  -webkit-animation-delay: -1.2s;
  animation-delay: -1.2s;
}

.sk-wave .sk-rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-wave .sk-rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-wave .sk-rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-wave .sk-rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

@-webkit-keyframes sk-waveStretchDelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}

@keyframes sk-waveStretchDelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
    transform: scaleY(0.4);
  }
  20% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
  }
}
</style>
<script>
import {useRoute, useRouter} from "vue-router";
import {useStore} from "vuex";
import {ACT_GET_STORE_LOGIN_INFO, ACT_SSO_AUTH_VDI, TOKEN_KEY} from "@/store/modules/auth/auth";
import {isSuccess} from "@/assets/js/util";
import {saveToken} from "@/assets/js/localstorage.service";
import {ref} from "vue";
import {ssoFail} from "@/assets/js/modules/common/common-message";
import {isInApp} from "@/assets/js/navigationUtils";
import {nativeLoginInfoCall} from "@/assets/js/nativeAppUtils";
import {useAlert} from '@/assets/js/modules/common/alert';
import {customPageMove} from '@/assets/js/modules/common/common';
const {showMessage} = useAlert();



export default {
  name: 'SSOVdi',
  setup(){
    const route = useRoute();
    const router = useRouter();
    const store = useStore();

    const isCustomPage = ref(false);
    const goPathStr = ref('');
    const waitStr = ref('');

    const noSession = (msg) => {
      // console.log(ssoFail);
      showMessage(msg);
      let elems = document.getElementsByClassName("sk-rect");
      for(let i=0; i<elems.length; i++){
        console.log(elems[i]);
        elems[i].style.display = "none";
      }
      try {
        waitStr.value = msg;
        document.getElementById("waitStr").innerHTML = msg;
      }catch (e) {
        console.log(e)
      }
    }

    const goPath = () => {
      let path = route.query.path;
      if(path){
        if(path.indexOf('/app') === 0) path = path.replace('/app', '');

        if(path.indexOf('@')>=0){
          path = path.replace('@', '');
          isCustomPage.value = true;
          goPathStr.value = path;
          customPageMove(path);
          document.getElementById("waitStr").innerHTML =
              `<div onclick="location.href='/app/main'" style="font-size:1em;">해당페이지로 자동 연결되었습니다.<br/><br/>메인페이지로 이동하시려면<br/> [이곳]을 눌러주세요</div>`
          return;
        }else{
          router.push(path);
        }
      }else{
        router.push({name: 'main'});
      }
    }

    // const session = computed(() => store.state.auth.session);
    // if(session.value.lrnerId) {
    //   goPath();
    // }else{
    const auth = route.query.auth
    console.log('auth' , auth)
    if(auth){
      // SSO 시작
      store.dispatch(`auth/${ACT_SSO_AUTH_VDI}`, auth).then(res => {
        // 문제없음
        if(isSuccess(res) && res.authToken){
          //
          if(isInApp){
            store.dispatch(`auth/${ACT_GET_STORE_LOGIN_INFO}`, {mode: 'store', lrnerId: store.state.auth.session.lrnerId}).then(loginInfo => {
              if(loginInfo) {
                nativeLoginInfoCall(JSON.stringify(loginInfo.storeLoginInfo));
              }
            });
          }
          // auth token 등록
          saveToken(TOKEN_KEY, res.authToken);
          //log
          goPath();
        }else{
          //SSO 실패이유 구체적 표시
          let msg = ssoFail;
          try{
            msg = res.result.message;
          }catch (e) {
            console.log(e);
          }
          noSession(msg);
        }
      }).catch(e => {
        console.error(e);
        noSession(`기타오류 ${e.message}`);
      });
    }else{
      noSession(ssoFail);
    }
    // }

    return {
      customPageMove,
      isCustomPage,
      goPathStr,
      waitStr
    }
  },
  mounted(){
    console.log("chk", document.getElementById("waitStr").innerHTML);
    if(this.waitStr){
      document.getElementById("waitStr").innerHTML = this.waitStr;
    }
  }
}
</script>